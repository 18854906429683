<template>
	<div>
		<div
			v-if="editor"
			class="editor-buttons"
		>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="grassetto"
				:class="{ active: editor.isActive('bold') }"
			>
				Grassetto
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="corsivo"
				:class="{ active: editor.isActive('italic') }"
			>
				Corsivo
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="paragrafo"
				:class="{ active: editor.isActive('paragraph') }"
			>
				Paragrafo
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="h1"
				:class="{ active: editor.isActive('heading', { level: 1 }) }"
			>
				h1
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="h2"
				:class="{ active: editor.isActive('heading', { level: 2 }) }"
			>
				h2
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="h3"
				:class="{ active: editor.isActive('heading', { level: 3 }) }"
			>
				h3
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="h4"
				:class="{ active: editor.isActive('heading', { level: 4 }) }"
			>
				h4
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="h5"
				:class="{ active: editor.isActive('heading', { level: 5 }) }"
			>
				h5
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="h6"
				:class="{ active: editor.isActive('heading', { level: 6 }) }"
			>
				h6
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="ul"
				:class="{ active: editor.isActive('bulletList') }"
			>
				Lista non ordinata
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="ol"
				:class="{ active: editor.isActive('orderedList') }"
			>
				Lista ordinata
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="cit"
				:class="{ active: editor.isActive('blockquote') }"
			>
				Citazione
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="newLine"
			>
				A capo
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="cancel"
			>
				Annulla
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="redo"
			>
				Ripristina
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="highlight"
				:class="{ active: editor.isActive('highlight') }"
			>
				Evidenzia
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="alignL"
				:class="{ active: editor.isActive({ textAlign: 'left' }) }"
			>
				Destra
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="alignC"
				:class="{ active: editor.isActive({ textAlign: 'center' }) }"
			>
				Centro
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="alignR"
				:class="{ active: editor.isActive({ textAlign: 'right' }) }"
			>
				Sinistra
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="justify"
				:class="{ active: editor.isActive({ textAlign: 'justify' }) }"
			>
				Giustificato
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="setLink"
				:class="{ active: editor.isActive('link') }"
			>
				Inserisci link
			</button>
			<button
				class="btn btn-dark btn-ghost-dark"
				@click="editor.chain().focus().unsetLink().run()"
				:disabled="!editor.isActive('link')"
			>
				Rimuovi link
			</button>
		</div>
		<editor-content :editor="editor" />
	</div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Paragraph from '@tiptap/extension-paragraph'
import TextAlign from '@tiptap/extension-text-align'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'

export default {
	name: 'Editor',
	components: {
		EditorContent,
	},
	props: {
		modelValue: {
			type: String,
			default: '',
		},
	},
	emits: {
		modelChange: {
			type: String
		}
	},
	data() {
		return {
			editor: null,
		}
	},
	methods: {
		avoidDefo(e) {
			e.preventDefault();
			e.stopImmediatePropagation();
		},
		grassetto(e) {
			this.avoidDefo(e);
			this.editor.chain().focus().toggleBold().run();
		},
		corsivo(e) {
			this.avoidDefo(e);
			this.editor.chain().focus().toggleItalic().run();
		},
		paragrafo(e) {
			this.avoidDefo(e);
			this.editor.chain().focus().setParagraph().run();
		},
		h1(e) {
			this.avoidDefo(e);
			this.editor.chain().focus().toggleHeading({ level: 1 }).run();
		},
		h2(e) {
			this.avoidDefo(e);
			this.editor.chain().focus().toggleHeading({ level: 2 }).run();
		},
		h3(e) {
			this.avoidDefo(e);
			this.editor.chain().focus().toggleHeading({ level: 3 }).run();
		},
		h4(e) {
			this.avoidDefo(e);
			this.editor.chain().focus().toggleHeading({ level: 4 }).run();
		},
		h5(e) {
			this.avoidDefo(e);
			this.editor.chain().focus().toggleHeading({ level: 5 }).run();
		},
		h6(e) {
			this.avoidDefo(e);
			this.editor.chain().focus().toggleHeading({ level: 6 }).run()
		},
		ul(e) {
			this.avoidDefo(e);
			this.editor.chain().focus().toggleBulletList().run();
		},
		ol(e) {
			this.avoidDefo(e);
			this.editor.chain().focus().toggleOrderedList().run();
		},
		cit(e) {
			this.avoidDefo(e);
			this.editor.chain().focus().toggleBlockquote().run();
		},
		newLine(e) {
			this.avoidDefo(e);
			this.editor.chain().focus().setHardBreak().run();
		},
		cancel(e) {
			this.avoidDefo(e);
			this.editor.chain().focus().undo().run();
		},
		redo(e) {
			this.avoidDefo(e);
			this.editor.chain().focus().redo().run();
		},
		highlight(e) {
			this.avoidDefo(e);
			this.editor.chain().focus().toggleHighlight().run();
		},
		alignL(e) {
			this.avoidDefo(e);
			this.editor.chain().focus().setTextAlign('left').run();
		},
		alignC(e) {
			this.avoidDefo(e);
			this.editor.chain().focus().setTextAlign('center').run();
		},
		alignR(e) {
			this.avoidDefo(e);
			this.editor.chain().focus().setTextAlign('right').run();
		},
		justify(e) {
			this.avoidDefo(e);
			this.editor.chain().focus().setTextAlign('justify').run();
		},
		setLink(e) {
			debugger;
			this.avoidDefo(e);
			const previousUrl = this.editor.getAttributes('link').href;
			const url = window.prompt('URL', previousUrl);

			// cancelled
			if (url === null) {
				return
			}

			// empty
			if (url === '') {
				this.editor
					.chain()
					.focus()
					.extendMarkRange('link')
					.unsetLink()
					.run()

				return
			}

			// update link
			this.editor
				.chain()
				.focus()
				.extendMarkRange('link')
				.setLink({ href: url })
				.run()
		},
	},
	watch: {
		modelValue(value) {
			const isSame = this.editor.getHTML() === value
			if (isSame) {
				return
			}
			this.editor.commands.setContent(this.modelValue, false);
		},
	},
	mounted() {
		this.editor = new Editor({
			content: this.modelValue,
			extensions: [
				StarterKit,
				Paragraph,
				Highlight,
				TextAlign.configure({
					types: ['heading', 'paragraph'],
				}),
				Link.configure({
					openOnClick: false,
				}),
			],
			onUpdate: () => {
				this.$emit('modelChange', this.editor.getHTML());
			},
		})
	},
	beforeUnmount() {
		this.editor.destroy();
	},
}
</script>

<style lang="scss">
/* Basic editor styles */
.editor-buttons {
	& > button {
		&:not(:last-of-type) {
			margin-right: 0.5rem;
			margin-bottom: 0.25rem;
		}
	}
}
.ProseMirror {
	margin-top: 1.5rem;
	min-height: 300px;
	padding: 1rem;
	background: whitesmoke;

	&:focus-visible {
		outline: #dad2d2 auto 1px;
	}

	> * + * {
		margin-top: 0.75em;
	}

	ul,
	ol {
		padding: 0 1rem;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: 1.1;
	}

	a {
		color: #68cef8;
	}

	code {
		background-color: rgba(#616161, 0.1);
		color: #616161;
	}

	pre {
		background: #0d0d0d;
		color: #fff;
		font-family: "JetBrainsMono", monospace;
		padding: 0.75rem 1rem;
		border-radius: 0.5rem;

		code {
			color: inherit;
			padding: 0;
			background: none;
			font-size: 0.8rem;
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}

	hr {
		margin: 1rem 0;
	}

	blockquote {
		padding-left: 1rem;
		border-left: 2px solid rgba(#0d0d0d, 0.1);
	}
}
</style>