<template>
	<CRow>
		<CCol col="12">
			<CCard>
				<CCardHeader>
					Editor per i consigli nutrizionali
				</CCardHeader>
				<CCardBody>
					<CForm>
						<CRow>
							<CCol
								lg="12"
								class="mb-sm-2 mb-0"
							>
								<tiptap
									@modelChange="setModel"
									:modelValue="advice"
								/>
							</CCol>
						</CRow>
					</CForm>
				</CCardBody>
				<CCardFooter>
					<div class="footer-actions">
						<CButton
							color="sdAzure"
							@click="updateAdvice"
						>Aggiorna</CButton>
					</div>
				</CCardFooter>
			</CCard>
		</CCol>
	</CRow>
</template>

<script>

import { isValidUUID, deleteModalOptions, pushToast } from "../../utilities/utilities";
import { mapActions } from "vuex";
import Tiptap from "../components/Editor.vue";

export default {
	name: 'ConsigliNutrizionali',
	components: {
		Tiptap
	},
	created() {
		this.$store.dispatch("getAdvice").then(obj => {
			this.advice = obj.content;
		});
	},
	data() {
		return {
			advice: "",
		}
	},
	methods: {
		...mapActions(["updateAdvice", "getAdvice"]),
		setModel(value) {
			this.advice = value;
		},
		updateAdvice() {
			this.$store.dispatch('updateAdvice', {
				content: this.advice,
			}).then(x => {
				this.pushToast({ title: 'Aggiornato', body: 'Consigli nutrizionali aggiornati', color: 'success' });
			});
		},
		isValidUUID,
		deleteModalOptions,
		pushToast
	}
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>